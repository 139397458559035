<!-- 商戶信息 列表页面 -->
<template>
    <div class="mt-4 mx-4">
        <ath-breadcrumbs :items="navigationItems" flat="false" txtColor="" bgColor=""/>
        <v-row>
            <v-col>
                <v-autocomplete  v-model="merId" :label="this.$t('mersInfo.queryStr')"
                    :items="merIdItems"
                    :item-text="'label'"
                    :item-value="'value'"/>
            </v-col>
            <v-col style=" margin-top:15px;">
                <div style="width:220px;">
                    <ath-button  class="primary"  icon="mdi-reload" :text="this.$t('common.operate.reset')" @click="clickReset" />
                    <ath-button  class="primary"  style="margin-left: 30px"  :text="this.$t('label.search')" @click="searchBtn" icon="mdi-magnify"/>
                </div>

            </v-col>
        </v-row>

        <ath-data-table style="margin-top: 12px"
            :headers="getHeaders"
            :items="items"
            :record-actions="recordActions"
            @action:row="onActionRow"
            :total="total"
            @update:options="onUpdateOptions"
            :options.sync="dTableOptions"
        >
        </ath-data-table>
    </div>

</template>

<script>
export default {
    name: 'MersInfo',
    props: {},
    data() {
        return {
            navigationItems: [
                {text:this.$t('menu.mersInfo'),disabled:true,to:''}
            ],
            headers: [],
            items: [],
            total: 0,
            options: '',
            index:0,
            dTableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                multiSort: false,
                mustSort: false,
            },
            merId:"",
            merIdItems: [],
        };
    },
    watch: {
        '$athlon.i18n.locale': {
            handler(newValue) {
                this.navigationItems = [
                    {text:this.$t('menu.mersInfo'),disabled:true,to:''}
                ];
                this.fetchData();
            },
            deep: true
        }
    },
    computed: {
        getHeaders(){
            let headers = [
                { text: this.$t('mersInfo.merId'), value: 'merId', sortable:false},
                { text: this.$t('mersInfo.merName'), value: 'merName', sortable:false},
                { text: this.$t('mersInfo.regAdd'), value: 'regAddr', sortable:false},
                { text: this.$t('mersInfo.contactCellNo'), value: 'contactCellNo', sortable:false},
                { text: this.$t('mersInfo.contactEmailAddr'), value: 'contactEmailAddr', sortable:false},
                { text: this.$t('mersInfo.custSrvNo'), value: 'custSrvNo', sortable:false},

            ];
            return headers;
        },
        recordActions(){
            let edit = [
                { name: 'view', icon: 'mdi-magnify', text: this.$t('action.view') }
            ]
            return edit;
        }
    },
    created() {
        this.initData();
    },

    methods: {
        onUpdateOptions(options) { //后端分页，监听翻页事件
            this.delRerocds = [];
            this.options = options;
            if(this.items.length > 0){
                this.fetchData();
            }
        },
        initData(){
            this.$loading(this.$t('msg.loading'));
            this.merIdItems = [];
            let params = {}
            this.$athlon.request
            .post('mersInfo/getDict', params, this.$athlon.config.api.requestParamheaders)
            .then((res) => {
                if (res.data.merchantsList != null) {
                    this.merIdItems.push({ label: this.$t('athlon.common.all'), value: '' });
                    this.merId = this.merIdItems[0].value;
                    for (let key in res.data.merchantsList) {
                        this.merIdItems.push({
                            value: key,
                            label: res.data.merchantsList[key]
                        });
                    }
                }
                this.fetchData();
                this.$loading(false);
            })
            .catch((err) => {
                this.$loading(false);
                this.$snackbar(this.$t('error.500')+`：${err.message}`);
            });
        },
        fetchData(){
            this.$loading(this.$t('msg.loading'));
            let limit = this.options.itemsPerPage || 10;
            let offset = (this.options.page - 1) * this.options.itemsPerPage || 0;
            if(limit == -1){
                limit = 1000;
            }
            const params = {
                "merId" : this.merId,
                "startRowId": offset + 1,
                "endRowId": offset + limit
                };
            this.$athlon.request
                .post('mersInfo/getMersInfoList',params, this.$athlon.config.api.requestParamheaders)
                .then((res) => {
                    this.total = res.data.totalCount;
                    this.items = res.data.dataList;
                    this.$loading(false);
                })
                .catch((err) => {
                    this.$loading(false);
                    this.$snackbar(this.$t('error.500')+`：${err.message}`);
                });
        },
        searchBtn(){
            this.dTableOptions.page=1;
            this.dTableOptions.itemsPerPage=this.options.itemsPerPage;
            this.fetchData();
        },
        clickReset(){
            this.merId = "";
            this.fetchData();
        },
    },

};

</script>
